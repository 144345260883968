/* global AO_PAGE_IS_LANDING, AO_BASE_PATH, AO_LANGUAGE_CODE */
import 'unfetch/polyfill';
import 'lightslider';
import { h, render } from 'preact';
import HotelSearch from '@adrias/sulley/lib/components/HotelSearch';
import FavoritesDropdown from '@adrias/sulley/lib/components/FavoritesDropdown';
import { createRangeDatePickers } from '@adrias/sulley/lib/datepickers';
// import { createRangeDatePickers } from './ux/datepickers';

// import '../styles/style.sass';

// import './helpers/jquery-inViewport';
import './ux/general';
import './ux/favorites';
import './ux/form';
import './ux/evento';
import emitter from './emitter';
import definition from './definition';
import favoritesHelper from './helpers/favorites-helper';
import landingHelper from './helpers/landing-helper';
import './helpers/form-helper';
import api from './api';
import scrollToElement from 'scroll-to-element';

// Creo funzione carica filtri
async function loadFilters(options) {
    const {
        default: Filters
    } = await import(/* webpackChunkName: "filters" */ '@adrias/sulley/lib/components/Filters');

    const containerId =
        window.innerWidth >= 992 || options.isLanding
            ? 'select_filters'
            : 'select_filters_top';

    render(
        <Filters
            {...options}
            definition={definition}
            emitter={emitter}
            filterData={api.filterData}
            helper={options.isLanding ? landingHelper : null}
            isMobile={window.innerWidth < 992}
        />,
        document.getElementById(containerId)
    );
}

window.loadFilters = loadFilters;

async function loadSchedaUi(mapConfig, hotel, places) {
    const scheda = await import(/* webpackChunkName: "schedaUi" */ './ux/scheda');

    scheda.loadSchedaMap(mapConfig, hotel, places);
    scheda.loadUi();
}

window.loadSchedaUi = loadSchedaUi;

async function loadLandingUi() {
    await import(/* webpackChunkName: "landingUi" */ './ux/landing');
}

window.loadLandingUi = loadLandingUi;

export function goToForm(idForm, callBack) {
    scrollToElement(idForm, {
        offset: -75,
        ease: 'in-out-quart',
        duration: 1500
    });

    callBack();
}

$(document).ready(() => {
    // Crea eventuali datepickers
    createRangeDatePickers('#arrivo', '#partenza', AO_LANGUAGE_CODE);

    if (
        typeof AO_PAGE_IS_LANDING === 'undefined' ||
        AO_PAGE_IS_LANDING === false
    ) {
        // Crea componente ricerca hotel
        render(
            <HotelSearch
                definition={definition}
                searchHotel={api.searchHotel}
            />,
            document.getElementById('hotel-search-input')
        );

        // Crea componente dropdown preferiti
        render(
            <FavoritesDropdown
                definition={definition}
                basePath={AO_BASE_PATH}
                emitter={emitter}
                getFavorites={api.getFavorites}
                helper={favoritesHelper}
            />,
            document.getElementById('hotel-favourites-dropdown')
        );
    }

    const btnPrev = $('.btn-contatta-hotel');

    btnPrev.on('click', e => {
        e.preventDefault();

        const howMuch = favoritesHelper.getAll();

        const is_btn_close = e.target.className.includes('fa fa-times');

        if (!is_btn_close) {
            howMuch.length > 0
                ? (location.href = btnPrev.attr('href'))
                : showModal();
        }
    });

    function showModal() {
        const modal_backdrop = document.getElementById('modal-backdrop-hotels');

        const no_hotels_modal = document.getElementById('no_hotels_modal');

        const btn_close_modal = document.getElementById('modal-hotels-close');

        const closeBtn = $('.j--close-no-hotel-modals');

        const formBtn = $('.j--form-hotel');

        const formId = document.getElementById('formContatti');

        modal_backdrop.classList.add('show-back');
        no_hotels_modal.classList.add('show-modal');

        btn_close_modal.addEventListener('click', () => {
            modal_backdrop.classList.remove('show-back');
            no_hotels_modal.classList.remove('show-modal');
        });

        formBtn.on('click', e => {
            e.preventDefault();

            if ($(window).innerWidth() < 576) {
                goToForm(formId, () => $('input[name="nome"]').focus());
            } else {
                $('input[name="nome"]').focus();
            }

            modal_backdrop.classList.remove('show-back');
            no_hotels_modal.classList.remove('show-modal');
        });

        closeBtn.on('click', e => {
            e.preventDefault();
            modal_backdrop.classList.remove('show-back');
            no_hotels_modal.classList.remove('show-modal');
        });
    }
});

import FavoritesHelper from '@adrias/sulley/lib/helpers/favoritesHelper';
import cookieHelper from './cookie-helper';
import definition from './../definition';
import emitter from './../emitter';

class CustomFavoritesHelper extends FavoritesHelper {
    toggle(id) {
        const isFavorited = this.isFavorited(id);
        const current = this.getAll().length;

        if (isFavorited) {
            this.remove(id, current);
            $(`.j--list-item-${id}`).each((i, e) => {
                $(e).removeClass('item-added');
            });
        } else {
            this.add(id, current);
            $(`.j--list-item-${id}`).each((i, e) => {
                $(e).addClass('item-added');
            });
        }
    }
}

export default new CustomFavoritesHelper({
    emitter,
    definition,
    cookieHelper,
    cookieFavoritesName: process.env.COOKIE_FAVORITES_NAME
});

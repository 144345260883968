import LandingHelper from '@adrias/sulley/lib/helpers/landingHelper';
import definition from './../definition';
import emitter from '../emitter';

class CustomLandingHelper extends LandingHelper {
    refreshUi() {
        // $('.g--ico-preferiti').html(this.selected.length);

        $('.item-add-to-landing').each((i, el) => {
            $(el).removeClass('item-added');
        });

        this.selected.forEach(id => {
            $(`#button_${id}`).addClass('item-added');
        });

        this.emitter.emit('landing:items:updated', this.getSelected());

        $('.preferiti-landing').html(this.selected.length);

        const btnMobileText =
            this.selected.length === 0
                ? 'preferiti_cta_mobile_zero'
                : 'preferiti_cta_mobile_n';

        $('#scritta').html(definition.general[btnMobileText]);
        // $('#num_hotel').html(this.selected.length);
        $('.num-preferiti').html(this.selected.length);
    }
}

const helper = new CustomLandingHelper({
    emitter
});

window.landingHelper = helper;

export default helper;

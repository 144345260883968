import scrollToElement from 'scroll-to-element';

export function jsGoTo(id, offset) {
    const myElem = document.getElementById(id);
    // console.log('offset ' + offset);
    if (myElem) {
        offset = offset ? offset : 0;
        //console.log(offset);
        //window.scrollToElement(myElem, { duration: 4200, offset: 1125 });
        scrollToElement(myElem, {
            offset: -offset,
            ease: 'in-out-quart',
            duration: 1500
        });
    }
}

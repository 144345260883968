import Sticky from 'sticky-js';
import ouibounce from 'ouibounce';
import hopscotch from 'hopscotch';
import 'scrollto.js';
import LazyLoad from 'vanilla-lazyload';

import '../helpers/jquery-viewport';
import emitter from '../emitter';
import definition from '../definition';
import api from './../api';
import { jsGoTo } from './helpers';
import cookieHelper from './../helpers/cookie-helper';
import favoritesHelper from '../helpers/favorites-helper';
import landingHelper from '../helpers/landing-helper';
import newsletterHelper from '../helpers/newsletter-helper';

// Carico in maniera lazy le immagini
const lazy = new LazyLoad({
    elements_selector: '.lazy'
});

emitter.on('list:updated', () => lazy.update());

window.setupByeModal = function() {
    ouibounce(false, {
        aggressive: false,
        callback() {
            if (favoritesHelper.getAll().length >= 1) {
                $('#bye-modal').modal('toggle');
            }
        }
    });
};

window.setupByeModalLanding = function() {
    ouibounce(false, {
        aggressive: true,
        callback() {
            if (landingHelper.getSelected().length >= 1) {
                $('#bye-modal').modal('toggle');
            }
        }
    });
};

// Sticky elements
$(window).on('load resize orientationchange', () => {
    // let fixer = new Fixer();
    if (window.innerWidth > 992) {
        if ($('.js--fixed-el').length > 0) {
            new Sticky('.js--fixed-el');
        }
    }
});

// Bottone to top
$(window).on('load scroll resize', () => {
    // Le aggiorno dopo questi eventi per caricare le immagini on load
    lazy.update();

    const scrollpos =
        document.documentElement.scrollTop || document.body.scrollTop;
    const btn = $('.js--to-top');
    const elem = $('body.u--trasp-header');
    //console.log(scrollpos);
    if (scrollpos > 100) {
        btn.show();
        elem.removeClass('g--header-style-white');
    } else {
        btn.hide();
        elem.addClass('g--header-style-white');
    }
});

$(document).ready(() => {
    const $byeModal = $('#bye-modal');
    const {
        forms: { compila_tutti_campi }
    } = definition;

    // Gestione scroll
    if (location.hash) {
        // do the test straight away
        window.scrollTo(0, 0); // execute it straight away

        setTimeout(function() {
            window.scrollTo(0, 0); // run it a bit later also for browser compatibility
        }, 1);
    }

    const hash = window.location.hash.replace('#', '');

    if (hash) {
        const navOffset = $('.l--navbar-cnt').height() + 8;
        //console.log(navOffset);
        jsGoTo(hash, navOffset);
    }

    $('[data-toggle="tooltip"]').tooltip();

    $('.js--scrollto').on('click', e => {
        const $el = $(e.target).closest('.js--scrollto');
        const target = $el.attr('data-target');
        const offset = $el.attr('data-offset');
        // console.warn(target, offset);
        jsGoTo(target, offset);
    });

    // Chiudo bye modal al click del bottone apposito
    $byeModal.on('click', '.j--close-bye-modal', () => {
        $byeModal.modal('hide');
    });

    // Chiudo bye modal al click del tasto mostra form landing page
    $byeModal.on('click', '.js--show-form', () => {
        $byeModal.modal('hide');
    });

    // Select anno archivio
    $('.archivio-selects select').change(function() {
        const url = $(this).val();

        if (url !== '') {
            document.location = url;
        }
    });

    // Tasto cerca offerte homepage
    $('.btn-search-offers').on('click', e => {
        e.preventDefault();

        const formData = $('#search-offers-forms').serializeArray();

        const data = {};

        formData.forEach(input => {
            data[input.name] = input.value;
        });

        if (
            data.localita === '' ||
            data.arrivo === '' ||
            data.partenza === ''
        ) {
            alert(compila_tutti_campi);
            if (data.localita === '') {
                $("#search-offers-forms select[name='localita']").addClass(
                    'validationErrorBg'
                );
            }
            if (data.arrivo === '') {
                $("#search-offers-forms input[name='arrivo']").addClass(
                    'validationErrorBg'
                );
            }
            if (data.partenza === '') {
                $("#search-offers-forms input[name='partenza']").addClass(
                    'validationErrorBg'
                );
            }
            return false;
        }

        api.setSessionDates(data).then(() => {
            const { localita } = data;

            window.location = localita;
        });
    });

    // Dropdown elenco preferiti
    const $divDropDown = $('#hotel-favourites-dropdown');
    const $btnDropdown = $('.btn-contatta-hotel');

    const closeDropDown = function() {
        $divDropDown.hide();
    };

    let timer = setTimeout(closeDropDown, 300);

    if (window.innerWidth > 992) {
        $btnDropdown.mouseenter(() => {
            if (favoritesHelper.getAll().length === 0) {
                return false;
            }

            clearTimeout(timer);

            if (!$divDropDown.is(':visible')) {
                $divDropDown.show();
            }
        });

        $divDropDown.mouseleave(() => {
            if ($divDropDown.is(':visible')) {
                timer = setTimeout(closeDropDown, 300);
            }
        });

        $('body').click(event => {
            // ???
            $divDropDown.not($divDropDown.has(event.target)).hide();
        });
    }
});

$(document).ready(() => {
    const {
        forms: { compila_tutti_campi }
    } = definition;

    $(document).on('click', '.btn_subscribe_newsletter', function(e) {
        e.preventDefault();

        const formId = $(this).data('form');
        const $form = $(`#${formId}`);

        if (!newsletterHelper.validate($form)) {
            alert(compila_tutti_campi);
            return false;
        }

        newsletterHelper.subscribe($form);
    });

    $('.btn_unsubscribe_newsletter').click(function(e) {
        e.preventDefault();

        const formId = $(this).data('form');
        const $form = $(`#${formId}`);

        newsletterHelper.unsubscribe($form);
    });
});

window.addEventListener('load', () => {
    // Slider gallery
    $('.js--light-slider').each(function() {
        const $el = $(this),
            $item = $el.attr('data-item'),
            $itemSm = $el.attr('data-item-sm'),
            $itemXs = $el.attr('data-item-xs'),
            $width = $el.attr('data-width'),
            $thumb = $el.attr('data-thumb-n');
        // console.log('$thumb' + $thumb);
        $($el).lightSlider({
            gallery: $thumb > 1,
            item: $item > 1 ? $item : 1,
            autoWidth: $width === 'auto',
            slideWidth: $width > 1 ? $width : false,
            loop: true,
            thumbItem: $thumb < 1 ? $thumb : 12,
            slideMargin: 0,
            enableDrag: true,
            currentPagerPosition: 'left',
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        item: $itemSm < 1 ? $itemSm : $item,
                        slideMove: 1,
                        slideMargin: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        item: $itemXs < 1 ? $itemXs : $item,
                        slideMove: 1
                    }
                }
            ]
        });
    });
});

window.showHints = async function() {
    const {
        general: { avanti, indietro },
        hints: { hint_general_step_1, hint_general_step_2 }
    } = definition;

    const { CACHE_DEFAULT_TAG } = process.env;

    const deviceWidth = $(window).innerWidth();

    let hopPosition, secondStepTarget, firstStepTarget;

    deviceWidth < 992 ? (hopPosition = 'top') : (hopPosition = 'bottom');

    deviceWidth < 992
        ? (secondStepTarget = '.j--tour-2-bottom')
        : (secondStepTarget = '.j--tour-2');

    deviceWidth < 992
        ? (firstStepTarget = [
              '.item-add-to-favorites-small',
              '.item-add-to-favorites'
          ])
        : (firstStepTarget = '.item-add-to-favorites');

    let tour = {
        id: 'tour-preferiti',
        i18n: {
            nextBtn: avanti,
            prevBtn: indietro,
            doneBtn: 'Ok'
        },
        bubbleWidth: 200,
        steps: [
            {
                title: hint_general_step_1,
                target: firstStepTarget,
                placement: 'bottom',
                xOffset: -190,
                arrowOffset: 190
            },
            {
                title: hint_general_step_2,
                target: secondStepTarget,
                placement: hopPosition,
                arrowOffset: 'top',
                xOffset: 15
            }
        ],
        onEnd() {
            // console.warn('TOUR ENDED!');
            jsGoTo('u--top', '0');
            cookieHelper.set(`${CACHE_DEFAULT_TAG}_tour_base`, true, {
                expires: 2592000
            });
        }
    };

    const $searchItem = $('.search-item');

    window.addEventListener('load', () => {
        const cookieStatus = cookieHelper.get(`${CACHE_DEFAULT_TAG}_tour_base`);

        // Non animo gli oggetti gia presenti nella pagina on load
        $searchItem.each(function(i, e) {
            if ($(e).is(':in-viewport')) {
                $(e).addClass('not-animate');
            }
        });

        // Animo gli elementi presenti man mano che diventano visibili
        $searchItem.inViewport(function(px) {
            if (px) {
                if (!$(this).hasClass('not-animate')) {
                    $(this).addClass('animated fadeInDown');
                }
            }
        });

        // console.warn(cookieStatus);

        if (typeof cookieStatus === 'undefined') {
            hopscotch.startTour(tour, 0);
        }
    });
};

/* global AO_BASE_PATH, AO_LANGUAGE_CODE */
import { h, render } from 'preact';

import definition from '../definition';

async function loadEventoMap(event, hotels) {
    const {
        default: MapEventoController
    } = await import(/* webpackChunkName: "mappaEvento" */ '@adrias/sulley/lib/components/maps/MapEventoController');

    render(
        <MapEventoController
            definition={definition}
            event={event}
            hotels={hotels}
            iconBasePath={AO_BASE_PATH}
            gmapsKey={process.env.GOOGLE_MAPS_API_KEY}
            language={AO_LANGUAGE_CODE}
        />,
        document.getElementById('map-evento')
    );
}

window.loadEventoMap = loadEventoMap;

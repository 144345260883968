// Funzione per controllare il numero di camere "aperte"
function checkRooms() {
    const open = $('.camera-row-open').length;

    if (open > 1) {
        $('.rimuovi-camera').removeClass('d-none');
    } else {
        $('.rimuovi-camera').addClass('d-none');
    }

    if (open === 5) {
        $('.aggiungi-camera').addClass('d-none');
    } else {
        $('.aggiungi-camera').removeClass('d-none');
    }
}

$(document).ready(() => {
    // Aggiunge camera
    $('.aggiungi-camera').on('click', () => {
        $('.camera-row.d-none:first')
            .removeClass('d-none')
            .addClass('camera-row-open');

        checkRooms();
    });

    // Rimuovi camere
    $('.rimuovi-camera').on('click', () => {
        $('.camera-row.camera-row-open:last')
            .removeClass('camera-row-open')
            .addClass('d-none');

        checkRooms();
    });

    // Mostro/nascondo dinamicamente i campi eta dei bambini
    $('.camera-bambini').on('change', e => {
        const n_bambini = e.target.value;

        if (n_bambini > 0) {
            $('.j--etichetta-eta-bambini').show();
        } else {
            $('.j--etichetta-eta-bambini').hide();
        }

        const $parent = $(e.target).closest('.camera-row');

        $parent.find('.bambino-row').each((i, el) => {
            if (i >= n_bambini) {
                $(el).addClass('d-none');
            } else {
                $(el).removeClass('d-none');
            }
        });
    });

    // $(document).on('click', '.closeConfirmEmail', e => {
    //     formHelper.hideConfirmEmail();
    // });
});
